import withAuth from '@/hooks/withAuth';
import HomeComponent from '@/views/Home';

const Home = () => {
	return <HomeComponent />;
};

Home.displayName = 'Home';

export default withAuth(Home);
