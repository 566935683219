import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Image from 'next/image';
import Link from 'next/link';
import { Grid, Theme, Typography, Skeleton } from '@mui/material';
import InfoIcon from '@/assets/icons/infoIcon.svg';
import MetricIcon from '@/assets/icons/Metric.svg';
import { Chip } from '@/components';
import { RESET_CHIPS_FILTERS } from '@/store/actions';
import { useFundService } from '@/store/services/fund.service';
import { CalculationType, FundType } from '@/utils/types';

const styles = {
	container: {
		border: (theme: Theme) => `1px solid ${theme.borderColor.searchField}`,
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		padding: 3,
		backgroundColor: (theme: Theme) => theme.palette.common.white,
		borderBottom: (theme: Theme) => `1px solid ${theme.borderColor.searchField}`,
	},
	text: {
		color: '#0D1D24',
		fontSize: '18px',
		lineHeight: '22px',
	},
	cardContainer: {
		padding: 3,
		borderRadius: '8px',
		border: (theme: Theme) => `1px solid ${theme.borderColor.searchField}`,
		backgroundColor: (theme: Theme) => theme.palette.common.white,
	},
	cardText: {
		fontSize: '14px',
		lineHeight: '21px',
		color: 'text.secondary',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		WebkitLineClamp: '1',
		WebkitBoxOrient: 'vertical',
	},
	cardTextBold: {
		fontWeight: 600,
		fontSize: '28px',
		lineHeight: '33.6px',
	},
	chip: {
		'& .MuiChip-label': {
			color: '#4A573E',
			fontSize: '10px',
			lineHeight: '12px',
			textTransform: 'none',
		},
	},
	number: {
		fontSize: '24px',
		fontWeight: 'bold',
		lineHeight: '21px',
		color: '#000',
		alignText: 'center',
	},
	noTasks: {
		fontSize: '16px',
		color: '#000',
		alignText: 'center',
	},
};

const FundsProgress = () => {
	const dispatch = useDispatch();
	const { fundsProgress, isLoadingFunds } = useFundService();
	const [funds, setFunds] = useState<FundType[]>([]);

	const getFundsProgress = useCallback(async () => {
		const result = await fundsProgress();
		setFunds(result);
	}, [fundsProgress]);

	useEffect(() => {
		getFundsProgress();
	}, [getFundsProgress]);

	const calculateProgress = ({ doneTaskCount, taskCount }: CalculationType) =>
		Math.round((doneTaskCount / taskCount) * 100);

	if (isLoadingFunds)
		return <Skeleton variant="rectangular" width={'100%'} height={250} />;

	return (
		<Grid item xs={12}>
			{funds.map(
				(fund) =>
					!!fund.portcos?.length && (
						<Grid container direction="column" sx={styles.container} key={fund.id} mb={2}>
							<Grid item sx={styles.header}>
								<Grid container gap={1}>
									<Image src={MetricIcon} alt="icon" height={20} width={20} />
									<Typography sx={styles.text}>{fund.name} Progress</Typography>
								</Grid>
								<Link
									href="https://help.tablecloth.io/progress-reports"
									rel="noopener noreferrer"
									target="_blank"
								>
									<Image src={InfoIcon} alt="info" width={20} />
								</Link>
							</Grid>
							<Grid item>
								<Grid container spacing={1} p={3} rowSpacing={2}>
									{fund?.portcos?.map((portco: Record<string, any>) => (
										<Grid item key={`${fund.id}-${portco.id}`} xs={12} md={6} lg={3}>
											<Link
												href={`/tasks/custom-view?organization=${portco.organization.id}`}
												onClick={() => dispatch(RESET_CHIPS_FILTERS())}
											>
												<Grid
													container
													direction="column"
													gap={1}
													sx={styles.cardContainer}
												>
													<Grid item xs={6}>
														<Typography sx={styles.cardText} pb={1}>
															{portco.organization.name}
														</Typography>
													</Grid>

													<Grid item>
														<Grid
															container
															justifyContent="space-between"
															alignItems="center"
														>
															{portco.organization.taskCount > 0 ? (
																<Typography sx={styles.number}>
																	{`${calculateProgress(
																		portco.organization as CalculationType,
																	)} %`}
																</Typography>
															) : (
																<Typography sx={styles.noTasks}>
																	No current tasks
																</Typography>
															)}
															{portco.organization.taskCount > 0 && (
																<Chip
																	label={
																		portco.organization.overDueTaskCount
																			? `${portco.organization.overDueTaskCount} past due`
																			: 'None past due'
																	}
																	customStyle={{
																		...styles.chip,
																		...{
																			backgroundColor: portco.organization
																				.overDueTaskCount
																				? '#EFAFAB'
																				: '#F1FFE1',
																		},
																	}}
																/>
															)}
														</Grid>
													</Grid>
												</Grid>
											</Link>
										</Grid>
									))}
								</Grid>
							</Grid>
						</Grid>
					),
			)}
		</Grid>
	);
};

export default FundsProgress;
