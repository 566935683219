import Image from 'next/image';
import { useCallback, useEffect, useState } from 'react';
import { Grid, Typography, Theme, IconButton } from '@mui/material';
import DownloadIcon from '@/assets/icons/Download.svg';
import GuideIcon from '@/assets/icons/Guide.svg';
import Xls from '@/assets/icons/xls.svg';
import Doc from '@/assets/icons/Doc.svg';
import Pdf from '@/assets/icons/PdfDoc.svg';
import Photo from '@/assets/icons/photo.svg';
import { FileType } from '@/utils/types';
import { GUIDES_FOLDER_ID } from '@/utils/constants';
import { downloadFile } from '@/utils/helpers';
import { useFolderService } from '@/store/services/folder.service';

const styles = {
	container: {
		overflow: 'hidden',
		border: (theme: Theme) => `1px solid ${theme.borderColor.searchField}`,
		borderRadius: '4px',
		flexWrap: 'nowrap',
		height: '649px',
	},
	textContainer: {
		padding: 3,
		backgroundColor: (theme: Theme) => theme.palette.common.white,
		borderBottom: (theme: Theme) => `1px solid ${theme.borderColor.searchField}`,
	},
	cardContainer: {
		border: (theme: Theme) => `1px solid ${theme.borderColor.searchField}`,
		background: (theme: Theme) => theme.palette.common.white,
		padding: 2,
		borderRadius: '8px',
	},
};

const Guides = () => {
	const [files, setFiles] = useState<FileType[]>([]);
	const { getFolder } = useFolderService();

	const getFileIcon = (fileType: string) => {
		let src;
		switch (fileType) {
			case 'text/csv':
				src = Xls;
				break;
			case 'image/jpeg':
			case 'image/png':
				src = Photo;
				break;
			case 'application/pdf':
				src = Pdf;
				break;
			default:
				src = Doc;
		}
		return src;
	};

	const getGuides = useCallback(async () => {
		const result = await getFolder(GUIDES_FOLDER_ID);
		setFiles(result?.files || []);
	}, [getFolder]);

	useEffect(() => {
		getGuides();
	}, [getGuides]);

	return (
		<Grid container direction="column" sx={styles.container}>
			<Grid item>
				<Grid container gap="10px" alignItems="center" sx={styles.textContainer}>
					<Image src={GuideIcon} alt="GuideIcon" height={20} width={20} />
					<Typography fontSize="18px" color="#121212">
						Guides
					</Typography>
				</Grid>
			</Grid>
			<Grid item height="calc(100% - 71px)" overflow="auto">
				<Grid container p={3} gap={1}>
					{files.map((file, index) => (
						<Grid
							key={index}
							item
							container
							alignItems="center"
							sx={styles.cardContainer}
							gap={2}
						>
							<Grid item>
								<Image
									src={getFileIcon(file.type)}
									alt="fileIcon"
									height={40}
									width={40}
								/>
							</Grid>
							<Grid item flex="1">
								<Typography color="text.primary" fontWeight={300}>
									{file.name}
								</Typography>
							</Grid>
							<Grid item>
								<IconButton onClick={() => downloadFile(file.fileUrl, file.name)}>
									<Image src={DownloadIcon} alt="DownloadIcon" height={20} width={20} />
								</IconButton>
							</Grid>
						</Grid>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Guides;
