import React from 'react';
import { Grid } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { TextField, Modal } from '@/components';
import { isSubmitButtonDisabled } from '@/utils/helpers';
import { VideoSectionType } from '@/utils/types';
import { isEmpty } from 'lodash';

type ManageVideoSectionModalPropsType = {
	open: boolean;
	handleClose: () => void;
	loading?: boolean;
	title: string;
	btnTitle: string;
	submitVideoSection: (_arg: VideoSectionType, _resetForm: () => void) => void;
	videoSection: VideoSectionType;
};

const InitialVideoSectionState = {
	chipTitle: '',
	sectionTitle: '',
	videoUrl: '',
};

export const ManageVideoSectionModal = (props: ManageVideoSectionModalPropsType) => {
	const {
		open,
		handleClose,
		submitVideoSection,
		loading,
		title,
		btnTitle,
		videoSection,
	} = props;

	const validationSchema = Yup.object({
		chipTitle: Yup.string().required('Chip title is required'),
		sectionTitle: Yup.string().required('Section title is required'),
		videoUrl: Yup.string().required('Video url is required'),
	});

	const formik = useFormik({
		initialValues: isEmpty(videoSection) ? InitialVideoSectionState : videoSection,
		enableReinitialize: true,
		validationSchema,
		onSubmit: (values, { resetForm }) => {
			submitVideoSection(values, resetForm);
		},
	});

	const handleCloseModal = () => {
		handleClose();
		formik.resetForm();
	};

	return (
		<Modal
			title={title}
			open={open}
			handleClose={handleCloseModal}
			actionBtnTitle={btnTitle}
			handleSubmit={formik.handleSubmit}
			disabledBtn={isSubmitButtonDisabled(formik.touched, formik.errors)}
			loading={loading}
		>
			<Grid container spacing={2.8}>
				<Grid item xs={12}>
					<TextField
						required
						id="chipTitle"
						name="chipTitle"
						type="text"
						label="Chip Title"
						placeholder="Enter chip title"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.chipTitle}
						error={formik.touched.chipTitle && Boolean(formik.errors.chipTitle)}
						helperText={formik.touched.chipTitle && formik.errors.chipTitle}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						id="sectionTitle"
						name="sectionTitle"
						type="text"
						label="Section Title"
						placeholder="Enter Section title"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.sectionTitle}
						error={formik.touched.sectionTitle && Boolean(formik.errors.sectionTitle)}
						helperText={formik.touched.sectionTitle && formik.errors.sectionTitle}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						id="videoUrl"
						name="videoUrl"
						type="text"
						label="Video Url"
						placeholder="Enter video url"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.videoUrl}
						error={formik.touched.videoUrl && Boolean(formik.errors.videoUrl)}
						helperText={formik.touched.videoUrl && formik.errors.videoUrl}
					/>
				</Grid>
			</Grid>
		</Modal>
	);
};
