import { useCallback } from 'react';
import { useAxios } from '@/hooks/useAxios';

export const useVideoSectionService = () => {
	const { get, post, isLoading } = useAxios();

	const getVideoSection = useCallback(() => get('/video-section'), [get]);

	const updateVideoSection = (data: Record<string, unknown>) =>
		post('/video-section', data);

	return {
		getVideoSection,
		updateVideoSection,
		isLoadingVideoSection: isLoading,
	};
};
