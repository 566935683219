import { useAxios } from '@/hooks/useAxios';
import { CreateFolderType } from '@/utils/types';
import { useCallback } from 'react';

export const useFolderService = () => {
	const { post, get, patch, remove } = useAxios();

	const url = '/folders';

	const getFolders = useCallback(() => get(url), [get]);

	const getFolder = useCallback((id: string) => get(`${url}/${id}`), [get]);

	const createFolder = (data: CreateFolderType) => post(url, data);

	const updateFolder = (id: string, reqData: CreateFolderType) =>
		patch(`${url}/${id}`, reqData);

	const deleteFolder = (id: string) => remove(`${url}/${id}`);

	return { createFolder, getFolders, getFolder, updateFolder, deleteFolder };
};
