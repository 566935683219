import { useCallback } from 'react';
import { useAxios } from '@/hooks/useAxios';

export const useArticlesService = () => {
	const { get, isLoading } = useAxios();

	const getArticles = useCallback((query: any) => get('/articles', query), [get]);

	const getArticle = useCallback((id: string) => get(`/articles/${id}`), [get]);

	const getArticleKeywords = useCallback(() => get('/articles/keywords'), [get]);

	return { getArticles, getArticle, getArticleKeywords, isLoadingArticles: isLoading };
};
