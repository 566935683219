import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Link from 'next/link';
import Image from 'next/image';
import { Grid, Theme, Typography, Skeleton } from '@mui/material';
import InfoIcon from '@/assets/icons/infoIcon.svg';
import MetricIcon from '@/assets/icons/Metric.svg';
import { Chip } from '@/components';
import { RESET_CHIPS_FILTERS } from '@/store/actions';
import { useOrganizationService } from '@/store/services/organization.service';
import { CalculationType, OrganizationType } from '@/utils/types';

const styles = {
	container: {
		border: (theme: Theme) => `1px solid ${theme.borderColor.searchField}`,
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		padding: 3,
		backgroundColor: (theme: Theme) => theme.palette.common.white,
		borderBottom: (theme: Theme) => `1px solid ${theme.borderColor.searchField}`,
	},
	text: {
		color: '#0D1D24',
		fontSize: '18px',
		lineHeight: '22px',
	},
	cardContainer: {
		padding: 3,
		borderRadius: '8px',
		border: (theme: Theme) => `1px solid ${theme.borderColor.searchField}`,
		backgroundColor: (theme: Theme) => theme.palette.common.white,
	},
	cardText: {
		fontSize: '14px',
		lineHeight: '21px',
		color: 'text.secondary',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		WebkitLineClamp: '1',
		WebkitBoxOrient: 'vertical',
	},
	chip: {
		'& .MuiChip-label': {
			color: '#4A573E',
			fontSize: '10px',
			lineHeight: '12px',
			textTransform: 'none',
		},
	},
	number: {
		fontSize: '24px',
		fontWeight: 'bold',
		lineHeight: '21px',
		color: '#000',
		alignText: 'center',
	},
	noTasks: {
		fontSize: '16px',
		color: '#000',
		alignText: 'center',
	},
};

type OrganizationWithCountType = OrganizationType & {
	taskCount: number;
	doneTaskCount: number;
	overDueTaskCount: number;
};

const GPsProgress = () => {
	const dispatch = useDispatch();
	const { gpsProgress, isLoadingOrganizations } = useOrganizationService();
	const [gps, setGPs] = useState<OrganizationWithCountType[]>([]);

	const getGPsProgress = useCallback(async () => {
		const result = await gpsProgress();
		setGPs(result);
	}, [gpsProgress]);

	useEffect(() => {
		getGPsProgress();
	}, [getGPsProgress]);

	const calculateProgress = ({ doneTaskCount, taskCount }: CalculationType) =>
		Math.round((doneTaskCount / taskCount) * 100);

	if (!gps.length) {
		return null;
	}

	if (isLoadingOrganizations)
		return <Skeleton variant="rectangular" width={'100%'} height={250} />;

	return (
		<Grid item xs={12}>
			<Grid container direction="column" sx={styles.container}>
				<Grid item sx={styles.header}>
					<Grid container gap={1}>
						<Image src={MetricIcon} alt="icon" height={20} width={20} />
						<Typography sx={styles.text}>GPs Progress</Typography>
					</Grid>
					<Link
						href="https://help.tablecloth.io/progress-reports"
						rel="noopener noreferrer"
						target="_blank"
					>
						<Image src={InfoIcon} alt="info" width={20} />
					</Link>
				</Grid>
				<Grid item>
					<Grid container spacing={1} p={3} rowSpacing={2}>
						{gps.map((gp: OrganizationWithCountType) => (
							<Grid item key={gp.id} xs={12} md={6} lg={3}>
								<Link
									href={`/tasks/custom-view?organization=${gp.id}`}
									onClick={() => dispatch(RESET_CHIPS_FILTERS())}
								>
									<Grid container direction="column" gap={1} sx={styles.cardContainer}>
										<Grid item xs={6}>
											<Typography sx={styles.cardText} pb={1}>
												{gp.name}
											</Typography>
										</Grid>

										<Grid item>
											<Grid container justifyContent="space-between" alignItems="center">
												{gp.taskCount > 0 ? (
													<Typography sx={styles.number}>
														{`${calculateProgress(gp as CalculationType)} %`}
													</Typography>
												) : (
													<Typography sx={styles.noTasks}>No current tasks</Typography>
												)}
												{gp.taskCount > 0 && (
													<Chip
														label={
															gp.overDueTaskCount
																? `${gp.overDueTaskCount} past due`
																: 'None past due'
														}
														customStyle={{
															...styles.chip,
															...{
																backgroundColor: gp.overDueTaskCount
																	? '#EFAFAB'
																	: '#F1FFE1',
															},
														}}
													/>
												)}
											</Grid>
										</Grid>
									</Grid>
								</Link>
							</Grid>
						))}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default GPsProgress;
