import { useCallback } from 'react';

import { useAxios } from '@/hooks/useAxios';
import {
	TaskFiltersType,
	TaskType,
	DeleteTaskType,
	MoveTaskType,
	BulkUpdateType,
	TaskSearchPayloadType,
} from '@/utils/types';

export const useTaskService = () => {
	const { get, post, patch, remove, isLoading } = useAxios();

	const getTasks = useCallback(
		(queryFilters: TaskFiltersType) => get('/tasks', queryFilters),
		[get],
	);

	const searchTasks = useCallback(
		(query?: TaskSearchPayloadType) => get('/tasks/search', query),
		[get],
	);

	const createTask = (
		reqData: Omit<TaskType, 'id' | 'friendlyId' | 'sortOrder'>,
	): Promise<TaskType> => post('/tasks', reqData);

	const getTask = useCallback((id: string) => get(`/tasks/${id}`), [get]);

	const updateTask = (
		id: string,
		reqData: Omit<TaskType, 'id' | 'friendlyId' | 'sortOrder'>,
		message?: string | null,
	) => patch(`/tasks/${id}`, reqData, message);

	const deleteTasks = (reqData: DeleteTaskType) => remove('/tasks', reqData);

	const moveTask = (reqData: MoveTaskType) => post('/tasks/move', reqData, '');

	const updateTasks = (reqData: BulkUpdateType) => patch('/tasks', reqData);

	const organizationProgress = useCallback(
		() => get('/tasks/organization-progress'),
		[get],
	);

	return {
		getTasks,
		searchTasks,
		createTask,
		getTask,
		updateTask,
		deleteTasks,
		moveTask,
		updateTasks,
		isLoadingTasks: isLoading,
		organizationProgress,
	};
};
