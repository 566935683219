import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Image from 'next/image';
import Link from 'next/link';
import { isEmpty } from 'lodash';
import { Grid, Skeleton, Theme, Typography } from '@mui/material';
import InfoIcon from '@/assets/icons/infoIcon.svg';
import ProgressIcon from '@/assets/icons/Progress.svg';
import { Chip } from '@/components';
import { RESET_CHIPS_FILTERS } from '@/store/actions';
import { RootState } from '@/store/slices';
import { useTaskService } from '@/store/services/task.service';
import { getDefaultAccount, snakeCaseToLabel } from '@/utils/helpers';

const styles = {
	container: {
		border: (theme: Theme) => `1px solid ${theme.borderColor.searchField}`,
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		padding: 3,
		backgroundColor: (theme: Theme) => theme.palette.common.white,
		borderBottom: (theme: Theme) => `1px solid ${theme.borderColor.searchField}`,
	},
	text: {
		color: '#0D1D24',
		fontSize: '18px',
		lineHeight: '22px',
	},
	cardContainer: {
		padding: 3,
		borderRadius: '8px',
		border: (theme: Theme) => `1px solid ${theme.borderColor.searchField}`,
		backgroundColor: (theme: Theme) => theme.palette.common.white,
	},
	cardText: {
		fontSize: '14px',
		lineHeight: '21px',
		color: 'text.secondary',
		textTransform: 'capitalize',
	},
	chip: {
		'& .MuiChip-label': {
			color: 'text.primary',
			fontSize: '10px',
			textTransform: 'none',
		},
	},
	number: {
		fontSize: '24px',
		fontWeight: 'bold',
		lineHeight: '21px',
		color: '#000',
		alignText: 'center',
	},
};

const TeamProgress = () => {
	const dispatch = useDispatch();
	const { data: user } = useSelector((state: RootState) => state.me);
	const account = getDefaultAccount(user as any);
	const { organizationProgress, isLoadingTasks } = useTaskService();
	const [progress, setProgress] = useState<Record<string, any>>({});

	const getOrganizationProgress = useCallback(async () => {
		const result = await organizationProgress();
		setProgress(result);
	}, [organizationProgress]);

	useEffect(() => {
		getOrganizationProgress();
	}, [getOrganizationProgress]);

	const formatChipText = (values: Record<string, any>) => {
		const assigned = Number(values.assignedtaskcount);
		const overdue = Number(values.overduetaskcount);
		let text = '';
		if (assigned > 0) {
			text += `${assigned} ${assigned === 1 ? 'is' : 'are'} yours`;
		}
		if (overdue > 0) {
			if (assigned > 0) {
				text += ' and ';
			}
			text += `${overdue} ${overdue === 1 ? 'is' : 'are'} overdue`;
		}
		if (!text.length) {
			text = 'None are yours';
		}
		return text;
	};

	const chipColor = (values: Record<string, any>) => ({
		backgroundColor: Number(values.overduetaskcount) ? '#EFAFAB' : '#F1FFE1',
	});

	if (isEmpty(progress)) {
		return null;
	}

	return (
		<Grid item xs={12}>
			<Grid container direction="column" sx={styles.container}>
				<Grid item sx={styles.header}>
					<Grid container gap={1}>
						<Image src={ProgressIcon} alt="icon" height={20} width={20} />
						<Typography sx={styles.text}>
							{account?.organization.name} Team Progress
						</Typography>
					</Grid>
					<Link
						href="https://help.tablecloth.io/progress-reports"
						rel="noopener noreferrer"
						target="_blank"
					>
						<Image src={InfoIcon} alt="info" width={20} />
					</Link>
				</Grid>
				<Grid item>
					<Grid container spacing={1} p={3} rowSpacing={2}>
						{isLoadingTasks ? (
							<>
								{[...new Array(3)].map((_, index) => (
									<Grid key={`${index}`} item xs={12} md={6} lg={4}>
										<Skeleton variant="rectangular" width={'100%'} height={108} />
									</Grid>
								))}
							</>
						) : (
							<>
								{Object.entries(progress).map(([taskStatus, values], index) => (
									<Grid key={`${index}`} item xs={12} md={6} lg={4}>
										<Link
											href={`/tasks/custom-view?organization=${account?.organization.id}`}
											onClick={() => dispatch(RESET_CHIPS_FILTERS())}
										>
											<Grid container sx={styles.cardContainer}>
												<Grid item xs={6}>
													<Typography sx={styles.cardText} pb={1}>
														{snakeCaseToLabel(taskStatus)}
													</Typography>
													<Chip
														label={formatChipText(values)}
														customStyle={{ ...styles.chip, ...chipColor(values) }}
													/>
												</Grid>
												<Grid item xs={6} container alignItems="center">
													<Typography sx={styles.number}>{values.taskcount}</Typography>
												</Grid>
											</Grid>
										</Link>
									</Grid>
								))}
							</>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default TeamProgress;
