import React from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { SecondaryHeader } from '@/components';
import ActionItems from './ActionItems';
import TeamProgress from './TeamProgress';
import GPsProgress from './GPsProgress';
import FundsProgress from './FundsProgress';
import RecommendedResources from './RecommendedResources';
import Live from './Live';
import Guides from './Guides';
import { RootState } from '@/store/slices';
import { getDefaultAccount, isSuperAdmin } from '../../utils/helpers';

const Overview = () => {
	const { data: user } = useSelector((state: RootState) => state.me);

	return (
		<>
			<SecondaryHeader title="Home" showSearch={false} showPrimaryBtn={false} />
			<Grid container wrap="nowrap" height="calc(100% - 136px)" bgcolor="#F9F9FB">
				<Grid item xs={12}>
					<Grid container spacing={2} overflow="auto" p={3}>
						<Grid item xs={6}>
							<RecommendedResources />
						</Grid>
						{user && !isSuperAdmin(user) && (
							<Grid item xs={6}>
								<ActionItems />
							</Grid>
						)}
						<Grid item xs={6}>
							<Guides />
						</Grid>
						<Grid item xs={6}>
							<Live />
						</Grid>
						{user && !isSuperAdmin(user) && (
							<>
								{user && getDefaultAccount(user)!.group.name !== 'Employee' && (
									<TeamProgress />
								)}
								{user && getDefaultAccount(user)!.organization.level === 'lp' && (
									<GPsProgress />
								)}
								{user && getDefaultAccount(user)!.organization.level !== 'portco' && (
									<FundsProgress />
								)}
							</>
						)}
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default Overview;
