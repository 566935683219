import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography, Theme, Skeleton } from '@mui/material';
import Image from 'next/image';
import { sortBy } from 'lodash';
import { TaskCard } from '@/components';
import AlarmIcon from '@/assets/icons/Alarm.svg';
import { useTaskService } from '@/store/services/task.service';
import { RootState } from '@/store/slices';
import { TASK_STATUS } from '@/utils/enums';
import { TaskType, UserType } from '@/utils/types';

const styles = {
	container: {
		overflow: 'hidden',
		border: (theme: Theme) => `1px solid ${theme.borderColor.searchField}`,
		borderRadius: '4px',
		height: '649px',
		flexWrap: 'nowrap',
	},
	textContainer: {
		padding: 3,
		backgroundColor: (theme: Theme) => theme.palette.common.white,
		borderBottom: (theme: Theme) => `1px solid ${theme.borderColor.searchField}`,
	},
	taskContainer: {
		width: '100%',
	},
};

const ActionItems = () => {
	const { getTasks: getTasksService, isLoadingTasks } = useTaskService();
	const [tasks, setTasks] = useState<TaskType[]>([]);
	const { data: user } = useSelector((state: RootState) => state.me);

	const filterTasks = useCallback(
		(tasks: TaskType[]) =>
			sortBy(tasks, 'dueDate').filter(
				(task) =>
					(task.approver?.id === user!.id ||
						task.assignedUsers!.some((u: UserType) => u.id === user!.id)) &&
					task.workflowStatus !== 'in_review',
			),
		[user],
	);

	const getTasks = useCallback(async () => {
		const tasks = await getTasksService({
			status: [TASK_STATUS.TODO, TASK_STATUS.IN_PROGRESS],
			pagination: false,
		});
		setTasks(filterTasks(tasks));
	}, [getTasksService, filterTasks]);

	useEffect(() => {
		getTasks();
	}, [getTasks]);

	return (
		<Grid container direction="column" sx={styles.container}>
			<Grid item>
				<Grid container gap="10px" alignItems="center" sx={styles.textContainer}>
					<Image src={AlarmIcon} alt="AlarmIcon" height={20} width={20} />
					<Typography fontSize="18px" color="#121212">
						{`Action Items${tasks.length ? ` (${tasks.length})` : ''}`}
					</Typography>
				</Grid>
			</Grid>
			<Grid item height="calc(100% - 71px)" overflow="auto">
				<Grid container p={3} gap={1}>
					{isLoadingTasks ? (
						<>
							{[...new Array(5)].map((_, index) => (
								<Grid key={index} item container alignItems="center" gap={2}>
									<Skeleton variant="rectangular" width={'100%'} height={101} />
								</Grid>
							))}
						</>
					) : (
						<>
							{tasks.map((task) => (
								<Grid item key={`card-${task.id}`} sx={styles.taskContainer}>
									<TaskCard task={task} />
								</Grid>
							))}
						</>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ActionItems;
