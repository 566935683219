import { Grid, Typography, Theme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Chip } from '@/components';
import { RootState } from '@/store/slices';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { ManageVideoSectionModal } from './ManageVideoSectionModal';
import { useVideoSectionService } from '@/store/services/videoSection.service';
import { isSuperAdmin } from '@/utils/helpers';
import { VideoSectionType } from '@/utils/types';

const styles = {
	container: {
		overflow: 'hidden',
		border: (theme: Theme) => `1px solid ${theme.borderColor.searchField}`,
		borderRadius: '4px',
		flexWrap: 'nowrap',
		height: '100%',
	},
	textContainer: {
		padding: 3,
		backgroundColor: (theme: Theme) => theme.palette.common.white,
		borderBottom: (theme: Theme) => `1px solid ${theme.borderColor.searchField}`,
	},
	chip: {
		backgroundColor: 'error.main',
		padding: '2px 10px',
		'& .MuiChip-icon': {
			width: '13px',
			color: '#611511',
		},
		'& .MuiChip-label': {
			padding: 0,
		},
	},
};

const Live = () => {
	const { data: user } = useSelector((state: RootState) => state.me);
	const [openVideoSectionModal, setOpenVideoSectionModal] = useState(false);
	const [videoSection, setVideoSection] = useState<VideoSectionType>(
		{} as VideoSectionType,
	);
	const {
		getVideoSection: getVideoSectionService,
		updateVideoSection,
		isLoadingVideoSection,
	} = useVideoSectionService();

	const getVideoSection = useCallback(async () => {
		const result = await getVideoSectionService();
		setVideoSection(result[0]);
	}, [getVideoSectionService]);

	useEffect(() => {
		getVideoSection();
	}, [getVideoSection]);

	const createEmbedURL = (url: string) => {
		if (url.includes('www.youtube.com')) {
			return url.replace('watch?v=', 'embed/');
		} else if (url.includes('www.loom.com')) {
			return url.replace('share', 'embed');
		}
		return url;
	};

	const handleOpenVideoSectionModal = () => {
		setOpenVideoSectionModal(true);
	};

	const handleCloseVideoSectionModal = () => {
		setOpenVideoSectionModal(false);
	};

	const submitVideoSection = async (values: VideoSectionType, resetForm: () => void) => {
		const result = await updateVideoSection(values);
		setVideoSection(result);
		handleCloseVideoSectionModal();
		resetForm();
	};

	return (
		<>
			<Grid container direction="column" sx={styles.container}>
				<Grid item>
					<Grid
						container
						gap="10px"
						alignItems="center"
						sx={styles.textContainer}
						flexWrap="nowrap"
					>
						{videoSection?.chipTitle && (
							<Grid item>
								<Chip label={videoSection?.chipTitle || ''} customStyle={styles.chip} />
							</Grid>
						)}
						<Grid item flex={1}>
							<Typography fontSize="18px" color="#121212">
								{videoSection?.sectionTitle || ''}
							</Typography>
						</Grid>
						{user && isSuperAdmin(user) && (
							<Grid item>
								<EditIcon
									sx={{ color: 'text.primary', cursor: 'pointer' }}
									onClick={handleOpenVideoSectionModal}
								/>
							</Grid>
						)}
					</Grid>
				</Grid>
				<Grid item height="calc(100% - 71px)" overflow="auto" p={3}>
					<iframe
						src={createEmbedURL(videoSection?.videoUrl || '')}
						width={'100%'}
						style={{ minHeight: '500px', border: 'unset', borderRadius: '8px' }}
					/>
				</Grid>
			</Grid>
			<ManageVideoSectionModal
				open={openVideoSectionModal}
				title="Video Section"
				handleClose={handleCloseVideoSectionModal}
				btnTitle="Update"
				submitVideoSection={submitVideoSection}
				loading={isLoadingVideoSection}
				videoSection={videoSection}
			/>
		</>
	);
};

export default Live;
