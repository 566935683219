import { useCallback } from 'react';
import { useAxios } from '@/hooks/useAxios';
import { INFINITE_SCROLL_TAKE } from '@/utils/constants';
import { FundType } from '@/utils/types';

export const useFundService = () => {
	const { get, post, patch, remove, isLoading } = useAxios();

	const getFunds = useCallback(
		(query: { page: number; search: string }) =>
			get('/funds', { ...query, take: INFINITE_SCROLL_TAKE }),
		[get],
	);

	const getFundsByGPId = useCallback(
		(id: string) => get('/funds', { pagination: false, organization: id }),
		[get],
	);

	const createFund = (reqData: FundType) => post('/funds', reqData);

	const updateFund = (id: string, reqData: FundType) => patch(`/funds/${id}`, reqData);

	const deleteFund = (id: string) => remove(`/funds/${id}`);

	const fundsProgress = useCallback(() => get('/funds/funds-progress'), [get]);

	return {
		getFunds,
		getFundsByGPId,
		createFund,
		updateFund,
		deleteFund,
		fundsProgress,
		isLoadingFunds: isLoading,
	};
};
